import * as React from "react"
import { Link } from "gatsby"

const NavLink = ({ to, children }) => (
  <Link
    to={to}
    className="font-normal text-sm text-zinc-500 hover:text-zinc-900 transition-colors duration-200"
  >
    {children}
  </Link>
)

export default function Footer() {
  return (
    <footer className="container py-7">
      <ul className="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-x-7 gap-y-10">
        <li className="space-y-5 row-span-2">
          <h2 className="text-xs font-semibold tracking-wide text-zinc-900 uppercase">
            Social
          </h2>
          <ul className="space-y-3">
            <li>
              <NavLink to="https://twitter.com/gill_kyle">Twitter</NavLink>
            </li>
            <li>
              <NavLink to="https://github.com/gillkyle">GitHub</NavLink>
            </li>
            <li>
              <NavLink to="https://dribbble.com/kylegill">Dribbble</NavLink>
            </li>
            <li>
              <NavLink to="https://www.linkedin.com/in/kyle-gill/">
                LinkedIn
              </NavLink>
            </li>
          </ul>
        </li>
        <li className="space-y-5 row-span-2">
          <h2 className="text-xs font-semibold tracking-wide text-zinc-900 uppercase">
            Writing
          </h2>
          <ul className="space-y-3">
            <li>
              <NavLink to="/essays">Essays</NavLink>
            </li>
            <li>
              <NavLink to="https://notes.kylegill.com">Notes</NavLink>
            </li>
            <li>
              <NavLink to="https://audio-vault.com">Music</NavLink>
            </li>
          </ul>
        </li>
        <li className="space-y-5 row-span-2">
          <h2 className="text-xs font-semibold tracking-wide text-zinc-900 uppercase">
            Personal
          </h2>
          <ul className="space-y-3">
            <li>
              <NavLink to="https://goals.kylegill.com">Goals</NavLink>
            </li>
            <li>
              <NavLink to="https://soljournal.netlify.app">Journal</NavLink>
            </li>
            <li>
              <NavLink to="https://study.kylegill.com">Study</NavLink>
            </li>
          </ul>
        </li>
        <li className="space-y-5 row-span-2">
          <h2 className="text-xs font-semibold tracking-wide text-zinc-900 uppercase">
            Mumbo Jumbo
          </h2>
          <ul className="space-y-3">
            <li>
              <NavLink to="https://www.youtube.com/watch?v=dQw4w9WgXcQ">
                Privacy Policy
              </NavLink>
            </li>
            <li>
              <NavLink to="https://www.youtube.com/watch?v=dQw4w9WgXcQ">
                © 2021, Kyle Gill
              </NavLink>
            </li>
          </ul>
        </li>
      </ul>
    </footer>
  )
}
