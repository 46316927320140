import { Analytics } from "@vercel/analytics/react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import * as React from "react"
import { Helmet } from "react-helmet"
import {
  TbHome2,
  TbLivePhoto,
  TbMessage,
  TbPencil,
  TbUser,
} from "react-icons/tb"
import Footer from "./footer"

const NavLink = ({ to, active, children }) => (
  <Link
    className={`flex flex-col sm:flex-row items-center px-2 sm:px-5 py-2 text-sm font-semibold hover:bg-amber-50 bg-opacity-60 hover:text-amber-500 sm:focus:ring focus:ring-offset-3 focus:ring-amber-200 outline-none focus:outline-none rounded-none sm:rounded-lg transition-all duration-200 cursor-pointer w-full sm:w-auto ${
      active
        ? `text-amber-600 bg-amber-50`
        : `text-zinc-400 bg:white sm:bg-zinc-50`
    }`}
    to={to}
  >
    {children}
  </Link>
)

export default function Layout({ location, children }) {
  return (
    <div>
      <Analytics />
      <Helmet titleTemplate="%s | Kyle Gill" />
      <div
        className="absolute top-0 right-0 left-0 opacity50 sm:opacity-100"
        style={{ zIndex: -2 }}
      >
        <div
          className="absolute w-screen"
          style={{
            height: `100vh`,
            background: `linear-gradient(to top, white 0%, white 25%, rgba(255, 255, 255, 0) 100%)`,
            zIndex: -1,
          }}
        />
        <StaticImage
          alt=""
          src="../images/background-icons.png"
          layout="fullWidth"
          placeholder="blurred"
          width={1240}
          backgroundColor="white"
          style={{ zIndex: -2, minHeight: 420 }}
        />
      </div>
      <div className="absolute top-8 w-full flex sm:hidden justify-center text-zinc-300 font-semibold tracking-wide">
        kg.
      </div>
      <div className="flex align-center justify-center">
        <header
          className="fixed z-10 bg-white bg-opacity-100 sm:bg-opacity-80 top-auto left-0 right-0 sm:right-auto sm:left-auto bottom-0 sm:top-4 sm:bottom-auto flex justify-between p-0 sm:py-1 sm:px-3 border-t sm:border sm:border-b rounded-none sm:rounded-full border-zinc-100 shadow-lg safe-area gap-1 shadow-zinc-800/5 ring-1 ring-zinc-200/50 backdrop-blur"
          style={{
            backdropFilter: `saturate(150%) blur(16px)`,
          }}
        >
          <div className="items-center hidden sm:flex mr-2">
            <span className="text-zinc-400 font-semibold tracking-wide">
              kg.
            </span>
          </div>
          <nav className="flex sm:space-x-1 w-full sm:w-auto justify-around">
            <NavLink active={location.pathname === "/"} to="/">
              <TbHome2 className="mr-0 sm:mr-1" />
              Home
            </NavLink>
            <NavLink active={location.pathname.includes("/about")} to="/about">
              <TbUser className="mr-0 sm:mr-1" />
              About
            </NavLink>
            <NavLink
              active={location.pathname.includes("/essays")}
              to="/essays"
            >
              <TbPencil className="mr-0 sm:mr-1" />
              Essays
            </NavLink>
            <NavLink
              active={location.pathname.includes("/projects")}
              to="/projects"
            >
              <TbLivePhoto className="mr-0 sm:mr-1" />
              Projects
            </NavLink>
            <NavLink
              active={location.pathname.includes("/contact")}
              to="/contact"
            >
              <TbMessage className="mr-0 sm:mr-1" />
              Contact
            </NavLink>
          </nav>
          <div className="hidden sm:flex items-center ml-2">
            <div className="h-3 w-3 rounded-full bg-gradient-to-br from-yellow-400 to-orange-500"></div>
          </div>
        </header>
      </div>
      <div className="min-h-screen pt-20">
        {children}
        <Footer />
      </div>
    </div>
  )
}
